<template>
  <div class="editData">
    <el-dialog
        title="排行榜"
        :visible.sync="dialogVisible"
        width="800px"
        :close-on-click-modal="false"
        @close="close"
    >
      <el-form
          :model="ruleForm"
          ref="ruleForm"
          :rules="rules"
          label-width="auto"
      >
        <el-row :gutter="20">
          <el-col :span="20" style="margin-bottom: 20px">
            选择类型：
            <el-select prop="type" v-model="ruleForm.type" placeholder="请选择" style="width: 20%;margin-right: 20px"
                       @change="change"
            >
              <el-option v-for="item in options" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
            时间：
            <el-date-picker
                style="width: 55%;"
                v-model="ruleForm.time"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                prop="time"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="24" style="margin-bottom: 20px">
            <span v-show="attr.username">用户ID：
              <el-input v-model="fromdata.username" style="width: 25%;margin:10px" placeholder="请输入用户ID"
                        prop="username"></el-input>
            </span>
            <span v-show="attr.order">排名：
              <el-input v-model="fromdata.order" style="width: 25%;margin:10px" placeholder="请输入内容"
                        prop="order">

              </el-input>
            </span>
            <br>
            <span v-show="attr.pk">{{ pkValue }}：
              <el-input v-model="fromdata.pk" style="width: 25%;margin:10px" placeholder="请输入内容" prop="pk"></el-input>
            </span>
            <span v-show="attr.box">应急箱：
              <el-input v-model="fromdata.box" style="width: 25%;margin:10px" placeholder="请输入内容" prop="box"></el-input>
            </span>
            <span v-show="attr.referrer">优秀推广员：
              <el-input v-model="fromdata.referrer" style="width: 25%;margin:10px" placeholder="请输入内容"
                        prop="referrer"></el-input>
            </span>
            <br>
            <span v-show="attr.sales">销售积分：
              <el-input v-model="fromdata.sales" style="width: 25%;margin:10px" placeholder="请输入内容"
                        prop="sales"></el-input>
            </span>
            <span v-show="attr.team">团队：
              <el-input v-model="fromdata.team" style="width: 25%;margin:10px" placeholder="请输入内容"
                        prop="team"></el-input>
            </span>
            <br>
            <span v-show="attr.money">预估奖励：
              <el-input v-model="fromdata.money" style="width: 25%;margin:10px" placeholder="请输入内容"
                        prop="team"></el-input>
            </span>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {createPlan, updatePlan} from "@/request/api";

export default {
  name: "AddDialog",
  components: {},
  data() {
    return {
      options: [{
        value: 1,
        label: '销售新星'
      }, {
        value: 2,
        label: '优秀推广员'
      }, {
        value: 3,
        label: '冠军名单'
      }, {
        value: 4,
        label: '卓越经理人'
      }
      ],
      value: "",
      seveId: "",
      action: "create",
      attr: [{
        username: false,
        pk: false,
        box: false,
        referrer: false,
        sales: false,
        order: false,
        money: false
      }],
      pkValue: "PK值",
      fromdata: {username: "", pk: "", type: '', box: '', referrer: '', sales: '', team: '', order: '', money: ''},
      ruleForm: {
        type: "",
        time: "",
      },
      rules: {
        type: [{required: true, message: "请选择类型", trigger: "blur"}],
        pk: [{required: true, message: "请填写内容", trigger: "blur"}],
        username: [{required: true, message: "请填写内容", trigger: "blur"}],
        time: [{required: true, message: "请选择时间", trigger: "blur"}],
      },
      dialogVisible: false,
    };
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {
    change(data) {
      switch (data) {
        case 1:
          this.pkValue = "PK值";
          this.attr = {
            username: true,
            id: true,
            box: true,
            team: true,
            referrer: false,
            pk: false,
            sales: false,
            order: true,
            money: false,
          }
          break;
        case 2:
          this.pkValue = "PK值";
          this.attr = {
            username: true,
            id: true,
            box: false,
            team: false,
            referrer: false,
            pk: false,
            sales: false,
            order: true,
            money: false,
          }
          break;
        case 3:
          this.attr = {
            username: true,
            id: true,
            box: false,
            team: false,
            referrer: false,
            pk: true,
            sales: false,
            order: true,
            money: false,
          }
          this.pkValue = "设备号";
          break;
        case 4:
          this.attr = {
            username: true,
            id: true,
            box: true,
            team: true,
            referrer: true,
            pk: false,
            sales: true,
            order: true,
            money: true,
          }
          this.pkValue = "积分";
          break;
      }
    },
    show(type, row) {
      this.dialogVisible = true;
      if (type === 2) {
        this.change(row.type)
        this.fromdata = {
          username: row.username,
          pk: row.pk,
          type: row.type,
          box: row.box,
          referrer: row.referrer,
          sales: row.sales,
          team: row.team,
          order: row.order,
          money: row.money
        }
        this.ruleForm.time = [row.time, row.e_time]
        this.ruleForm.type = row.type
        this.seveId = row.id
        this.action = 'edit'
      }
    },
    close() {
      this.dialogVisible = false;
    },
    submitForm() {
      // this.$refs.ruleForm.validate(async (valid) => {
      let params = {
        time: this.ruleForm.time[0],
        e_time: this.ruleForm.time[1],
        type: this.ruleForm.type,
        username: this.fromdata.username,
        pk: this.fromdata.pk,
        box: this.fromdata.box,
        referrer: this.fromdata.referrer,
        sales: this.fromdata.sales,
        team: this.fromdata.team,
        order: this.fromdata.order,
        money: this.fromdata.money,
        id: this.seveId,
      };
      if (this.action === 'create') {
        createPlan(params).then((res) => {
          if (res.data.code == 200) {
            this.$message.success("操作成功");
            this.close();
            this.$parent.list();
            this.fromdata = {
              username: "",
              pk: "",
              type: '',
              box: '',
              referrer: '',
              sales: '',
              team: '',
              order: '',
              money: ''
            }
            this.attr = {
              username: false,
              pk: false,
              box: false,
              referrer: false,
              sales: false,
              team: false
            }
            this.ruleForm.time = ""
            this.ruleForm.type = ""
          } else {
            this.$message.dannger(res.data.msg);
          }

        });
      } else {
        updatePlan(params).then((res) => {
          if (res.data.code == 200) {
            this.$message.success("操作成功");
            this.close();
            this.$parent.list();
            this.fromdata = {
              username: "",
              pk: "",
              type: '',
              box: '',
              referrer: '',
              sales: '',
              team: '',
              order: '',
              money: ''
            }
            this.attr = {
              username: false,
              pk: false,
              box: false,
              referrer: false,
              sales: false,
              team: false
            }
            this.ruleForm.time = ""
            this.ruleForm.type = ""
          } else {
            this.$message.dannger(res.data.msg);
          }

        });
      }
      // });
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
