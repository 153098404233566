<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true" :model="form" ref="form">
        <el-form-item label="类型" prop="type">
          <el-select
              v-model="form.type"
              placeholder="请选择"
              style="width: 150px"
          >
            <el-option label="销售新星" value="1"></el-option>
            <el-option label="优秀推广员" value="2"></el-option>
            <el-option label="冠军名单" value="3"></el-option>
            <el-option label="卓越经理人" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户id" prop="username">
          <el-input
              style="width: 180px"
              v-model="form.username"
              clearable
              placeholder="请输入用户id"
          ></el-input>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button type="primary" icon="el-icon-search" @click="search()"
          >查询
          </el-button
          >
          <el-button type="primary" @click="addOpen()" style="margin-bottom: 10px"
          >添加
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <page-table
        ref="dataTable"
        :data="pklist"
        @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
              (page.currentPage - 1) * page.pageSize + scope.$index + 1
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.type == 1" style="color: green">销售新星</span>
          <span v-if="scope.row.type == 2" style="color: red">优秀推广员</span>
          <span v-if="scope.row.type == 3" style="color: blue">冠军名单</span>
          <span v-if="scope.row.type == 4" style="color: deeppink">卓越经理人</span>
        </template>
      </el-table-column>

      <el-table-column prop="username" label="用户id" align="center">
      </el-table-column>
      <el-table-column prop="order" label="排名" align="center">
      </el-table-column>
      <el-table-column prop="box" label="应急箱积分" align="center">
      </el-table-column>
      <el-table-column prop="referrer" label="优秀推广员" align="center">
      </el-table-column>
      <el-table-column prop="sales" label="百万销售" align="center">
      </el-table-column>
      <el-table-column prop="team" label="团队" align="center">
      </el-table-column>
      <el-table-column prop="money" label="预估奖励" align="center">
      </el-table-column>
      <el-table-column label="操作" width="193" align="center">
        <template slot-scope="scope">
          <el-button @click="editOpen(2,scope.row)" type="text" size="small">
            编辑
          </el-button>
          <el-button @click="deleteCli(scope.row.id)" type="text" size="small">
            删除
          </el-button>
        </template>
      </el-table-column>
    </page-table>
    <!-- 新增编辑弹窗 -->
    <edit-data ref="editData"/>
  </div>
</template>

<script>
import {delPlan, pkPlan} from "@/request/api";
import {checkPermission} from "@/utils/permissions";

import pageTable from "@/components/pageTable.vue";
import editData from "./components/editData.vue";

export default {
  name: "user",
  components: {
    pageTable,
    editData,
  },
  data() {
    return {
      form: {
        type: "",
        username: ""
      },
      pklist: [],
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  created() {
    this.list();
  },
  methods: {
    addOpen() {
      this.$refs.editData.show(1, {});
    },

    editOpen(type, row) {
      this.$refs.editData.show(type, JSON.parse(JSON.stringify(row)));
    },

    handleData(row) {
      this.$router.push({
        path: "excellence",
        query: {
          id: row.id,
        },
      });
    },
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.list();
    },
    addData() {
      // 1:新增，2:编辑
      this.$refs.addData.show(1, {});
    },
    search() {
      let params = {
        page: 1,
        limit: this.page.pageSize,
        type: this.form.type,
        username: this.form.username,
      };
      console.log(params);
      pkPlan(params).then((res) => {
        this.page.total = res.data.data.total;
        this.pklist = res.data.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    list() {
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        type: this.type,
        username: this.username,
      };
      pkPlan(params).then((res) => {
        this.page.total = res.data.data.total;
        this.pklist = res.data.data.data;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    deleteCli(id) {
      this.$confirm("是否删除此信息？", "提示", {
        type: "warning",
      })
          .then(async () => {
            delPlan({id: id}).then((res) => {
              if (res.status == 200) {
                this.list();
                this.$message.success("删除成功");
              }
            });
          })
          .catch(() => {
          });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
